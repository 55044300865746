<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs" :externalFilter="filterByStopAtd"></pui-datatable>
		<customfields-modals></customfields-modals>
	</div>
</template>

<script>
import ShippositioningActions from './ShippositioningActions';

export default {
	name: 'finishedshippositioninggrid',
	components: {},
	data() {
		return {
			modelName: 'finishedshippositioning',
			actions: ShippositioningActions.gridactions,
			modelColumnDefs: {
				sts: {
					render: (data, type, row) => {
						return data ? `<i class="fas fa-check state-check"></i>` : '';
					}
				}
			}
		};
	},
	computed: {
		filterByStopAtd() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'atdstop', op: 'nn', data: null }]
			};
		}
	}
};
</script>
